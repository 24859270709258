$font: 'Gilroy';

@font-face {
  font-family: 'Gilroy';
  src: url('../../fonts/Gilroy-Regular.eot');
  src: url('../../fonts/Gilroy-Regular.eot?#iefix') format('embedded-opentype'),
  url('../../fonts/Gilroy-Regular.woff2') format('woff2'),
  url('../../fonts/Gilroy-Regular.woff') format('woff'),
  url('../../fonts/Gilroy-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Gilroy';
  src: url('../../fonts/Gilroy-Bold.eot');
  src: url('../../fonts/Gilroy-Bold.eot?#iefix') format('embedded-opentype'),
  url('../../fonts/Gilroy-Bold.woff2') format('woff2'),
  url('../../fonts/Gilroy-Bold.woff') format('woff'),
  url('../../fonts/Gilroy-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Gilroy';
  src: url('../../fonts/Gilroy-Medium.eot');
  src: url('../../fonts/Gilroy-Medium.eot?#iefix') format('embedded-opentype'),
  url('../../fonts/Gilroy-Medium.woff2') format('woff2'),
  url('../../fonts/Gilroy-Medium.woff') format('woff'),
  url('../../fonts/Gilroy-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Gilroy';
  src: url('../../fonts/Gilroy-Light.eot');
  src: url('../../fonts/Gilroy-Light.eot?#iefix') format('embedded-opentype'),
  url('../../fonts/Gilroy-Light.woff2') format('woff2'),
  url('../../fonts/Gilroy-Light.woff') format('woff'),
  url('../../fonts/Gilroy-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}


