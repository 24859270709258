.profile {
  position: relative;
  min-height: 100%;

  .profile-tab-content {
    min-height: 500px;
    padding: 62px 52px;

    form {
      align-items: flex-start;
      justify-content: flex-start;
    }
  }

  .tab-label {
    min-width: 250px;
  }

  .underline-link {
    display: flex;
    margin-top: 56px;
  }

  .profile__moderation {
    position: absolute;
    top: 15px;
    right: 15px;
    display: inline-flex;
  }

  .notification-tab {
    .notification-tab-title {
      display: flex;
      margin-bottom: 34px;
      font-weight: 500;
      font-size: 18px;
      line-height: 96%;
      color: $black;
    }
  }
}

.profile_moderation {
  position: absolute;
  top: 57px;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: auto;

  .confirm-step {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 120px;

    .confirm-step-title,
    .confirm-step-sub-title {
      max-width: 425px;
      margin: 0 auto;
    }

    .confirm-step-sub-title {
      margin-top: 34px;
    }

    .confirm-step__buttons {
      display: flex;
      justify-content: center;
      width: 100%;

      .btn {
        max-width: 350px;
        margin-top: 70px;

        + .btn {
          margin-left: 35px;
        }
      }
    }
  }
}

.profile-moderation {
  max-width: 350px;
  margin: auto;

  .profile-moderation__title {
    text-align: center;
    font-weight: 500;
    font-size: 18px;
    line-height: 96%;
    letter-spacing: 0.015em;
    color: $primaryBlue;
  }

  .profile-moderation-form {
    margin-top: 52px;
  }

  .profile-moderation-form__buttons {
    display: flex;
    width: 100%;

    .btn {
      width: 100%;

      + .btn {
        margin-left: 15px;
      }
    }
  }
}

.change-password-form {
  max-width: 434px;
}
