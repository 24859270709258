.ant-form {
  position: relative;

  .error {
    position: absolute;
    bottom: -32px;
    left: 0;
    right: 0;
    max-width: 350px;
    margin: 0 auto;
    height: 22px;
    color: $primaryRed;
    text-align: center;
  }
}

.field {
  &.field--edited {

    .ant-input {
      padding-right: 80px;
    }
  }

  .field__wrapper {
    position: relative;
  }

  .field__edit {
    position: absolute;
    z-index: 2;
    right: 16px;
    bottom: 16px;
    display: flex;
    align-items: center;
    height: 16px;
    border: none;
    padding: 0;
    background-color: transparent;
    font-weight: 500;
    font-size: 16px;
    letter-spacing: 0.01em;
    text-decoration-line: underline;
    color: var(--primaryBlueToGreen);
    cursor: pointer;
  }

  .ant-input-affix-wrapper,
  .field__input,
  .ant-input-number {
    height: 48px;
    padding: 4px 18px;
    border: 1px solid rgba(23, 24, 59, 0.4);
    color: $black;
    font-weight: normal;
    font-size: 16px;
    line-height: 96%;
    //border-radius: 10px;

    &:not(.ant-input-affix-wrapper-disabled):hover,
    &:not(.ant-input-affix-wrapper-disabled):focus,
    &:not(.ant-input-affix-wrapper-disabled):active {
      border-color: $primaryBlue;
      box-shadow: none;
    }

    &-focused {
      box-shadow: none;
      border-color: #000000;
    }
  }

  .ant-input[disabled],
  .ant-input[disabled]:hover,
  .ant-input-affix-wrapper-disabled,
  .ant-input-affix-wrapper-disabled:hover {
    background-color: #E1ECF6;
    border-color: rgba(23, 24, 59, 0.4);
    color: rgba(23, 24, 59, 0.4);
  }

  .tooltip {
    margin-left: 4px;
  }

  .ant-select-single .ant-select-selector .ant-select-selection-item,
  .ant-select-single .ant-select-selector .ant-select-selection-placeholder {
    display: flex;
    align-items: center;
    font-size: 16px;
  }

  .ant-form-item {
    display: flex;
    flex-direction: column;
    width: 100%;

    .ant-form-item {
      margin-bottom: 0;
    }
  }

  .ant-select-single.ant-select-show-arrow .ant-select-selection-item {
    padding-right: 32px;
  }

  .ant-select-arrow {
    right: 18px;
  }

  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    height: 40px;
    padding: 4px 16px;
    border: 1px solid $border;
    color: $black;
    font-weight: normal;
    font-size: 14px;
    line-height: 96%;
  }

  .ant-select-selection-item {
    display: flex;
    align-items: center;
  }

  .ant-select:not(.ant-select-disabled):hover .ant-select-selector {
    &:hover {
      border-color: #000000;
    }
  }

  .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
    box-shadow: none;
  }


  .ant-form-item-label {
    text-align: left;

    > label {
      align-items: flex-end;
      height: max-content;
      margin-bottom: 12px;
      font-weight: normal;
      font-size: 12px;
      line-height: 96%;
      letter-spacing: 0.01em;
      text-transform: uppercase;
      color: $primaryGrey;

      &:after {
        content: "";
      }

      .ant-form-item-tooltip {
        margin-inline-start: 8px;
      }
    }
  }

  .ant-input-prefix {
    margin-right: 10px;
  }

  .ant-input-suffix {
    color: rgba(23, 24, 59, 0.5);
  }

  .ant-input-password-icon {
    color: #A2A3B1;

    &:hover {
      color: #A2A3B1; // change
    }

    svg {
      width: 24px;
      height: 20px;
    }
  }

  .ant-input {
    color: $black;
    font-weight: normal;
    font-size: 16px;
    line-height: 96%;

    &:hover {
      border-color: rgba(23, 24, 59, 0.4);
    }
  }

  .ant-checkbox-wrapper {
    display: flex;
    align-items: center;

    .ant-checkbox {
      top: 0;
    }
  }

  .ant-checkbox-inner {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    width: 24px;
    height: 24px;
    padding: 0;
    border: 2px solid $primaryBlue;
    background-color: transparent;
    cursor: inherit;
    transition: border-color 0.3s, background-color 0.3s;

  }

  .ant-checkbox-checked .ant-checkbox-inner::after {
    position: absolute;
    display: table;
    border: 2px solid #fff;
    border-top: 0;
    border-left: 0;
    transform: rotate(45deg) scale(1) translate(-50%, -50%);
    opacity: 1;
    transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
    content: ' ';
  }

  .ant-checkbox-wrapper:hover .ant-checkbox-inner, .ant-checkbox:hover .ant-checkbox-inner, .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: $primaryBlue;
  }

  .ant-checkbox:hover::after, .ant-checkbox-wrapper:hover .ant-checkbox::after {
    visibility: hidden;
  }

  .ant-checkbox + span {
    padding-left: 12px;
  }

  .ant-checkbox-inner::after {
    position: absolute;
    top: 50%;
    left: 15%;
    display: table;
    width: 7px;
    height: 14px;
    border: 2px solid #fff;
    border-top: 0;
    border-left: 0;
    transform: rotate(45deg) scale(0) translate(-50%, -50%);
    opacity: 0;
    transition: all 0.1s cubic-bezier(0.71, -0.46, 0.88, 0.6), opacity 0.1s;
    content: ' ';
  }

  .ant-checkbox-checked {
    .ant-checkbox-inner {
      border-color: $primaryBlue;
      background-color: $primaryBlue;
    }

    &:after {
      border-color: $primaryBlue;
      background-color: $primaryBlue;
    }
  }

  &.switch-field {
    .ant-form-item {
      flex-direction: row;
      align-items: center;
    }

    .ant-form-item-control {
      min-height: auto;
      flex-grow: 0;
    }

    .ant-form-item-label {
      margin-left: 16px;
      order: 1;

      > label {
        margin-bottom: 0;
      }
    }
  }

  .ant-switch {
    width: 58px;
    height: 32px;
    border-radius: 2px;

    &.ant-switch-checked {
      background-color: $primaryBlue;

      .ant-switch-handle {
        left: calc(100% - 26px - 2px);

        &:before {
          border-color: #2b76ae;
        }
      }
    }

    .ant-switch-handle {
      width: 26px;
      height: 26px;
      top: 3px;

      &:before {
        background: $white;
        border: 0.5px solid rgba(0, 0, 0, 0.35);
        box-sizing: border-box;
        box-shadow: 0 1px 4px rgba(129, 129, 129, 0.1);
        border-radius: 2px;
      }
    }
  }
}

form {
  &:not(.form-grid) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  width: 100%;

  .checkbox {
    margin-top: 68px;

    .checkbox__label {
      text-align: left;
    }
  }

  &.order-gas-create-form {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .field:not(:last-child) {
      margin-bottom: 40px;

      @include mobile() {
        width: 100%;
      }
    }

    .btn, .ant-form-item-control-input {
      min-width: 280px;
    }

    .radio-list-content {
      @include mobile() {
        padding-left: 0px;
      }
    }
  }

  &.form-selected-filter {
    flex-direction: row;
    justify-content: flex-start;
    margin-bottom: 34px;

    @include mobile() {
      justify-content: space-between;
      flex-wrap: wrap;
      margin-bottom: 20px;
    }

    .field {
      min-width: 196px;
      width: max-content;
      margin-right: 24px;
      margin-bottom: 20px;

      @include mobile() {
        min-width: 47%;
        width: 47%;
        margin-right: 0;
      }
    }

    .ant-form-item {
      margin-bottom: 0;
    }

    .underline-link {
      margin-left: auto;
    }
  }

  .underline-link {
    color: rgba(36, 36, 36, 0.71);
  }

}

.registration {
  .field {
    width: 350px;
  }

  a {
    max-width: 350px;
    width: 100%;
  }

  a {
    .btn {
      width: 100%;
    }
  }
}


.profile {
  .field {
    max-width: 434px;
    width: 100%;
  }

  .btn {
    max-width: 434px;
    width: 100%;
    margin-top: 28px;
  }
}

.underline-link {
  padding: 0;
  border: none;
  background-color: transparent;
  font-weight: normal;
  font-size: 14px;
  line-height: 146.5%;
  text-decoration-line: underline;
  color: $primaryGrey;
  cursor: pointer;

  &:hover {
    text-decoration: none;
  }

  img {
    margin-right: 5px;
  }

  &--red {
    color: $primaryRed;

    &:hover {
      color: $primaryRed;
      text-decoration: none;
    }
  }

  &--back {
    color: #00B5E5;
    text-decoration: none;

    img {
      transform: rotate(180deg);
    }
  }
}


.form-grid {
  display: grid;
  justify-content: center;
  grid-auto-rows: 1fr;
  grid-column-gap: 46px;

  .checkbox-field {
    grid-column: 1 / 3;
  }

  .btn {
    grid-column: 1 / 3;
    width: 100%;
    margin: 28px auto 0 auto;
  }

  &.form-grid__reg {
    grid-template-columns: repeat(2, minmax(min-content, 350px));
    grid-auto-rows: max-content;
    grid-column-gap: 46px;

    @include desktop() {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .btn {
      max-width: 350px;
    }
  }

  &.form-grid__profile {
    grid-template-columns: repeat(2, minmax(min-content, 434px));
    grid-column-gap: 42px;

    .btn {
      max-width: 434px;
    }
  }

  &.form-grid-3cols {
    grid-template-columns: repeat(3, minmax(min-content, 246px));
  }
}

.ant-popover {
  width: 308px;

  .ant-popover-inner-content {
    span {
      display: flex;
      font-weight: normal;
      font-size: 12px;
      line-height: 131.5%;
      letter-spacing: -0.02em;
      color: $text;

      + span {
        margin-top: 2px;
      }
    }
  }
}

.radio-with-help-text {
  display: flex;
  align-items: center;

  img {
    cursor: pointer;
    margin-left: 8px;
  }

  &--false {
    .radio {
      &__btn {
        border: 2px solid $primaryGrey;
      }

      &__label {
        color: $primaryGrey;
      }

      &:hover {
        cursor: not-allowed;
        .radio {
          &__btn {
            border: 2px solid $primaryGrey;
          }
        }
      }
    }

    &:hover {
      .radio {
        &__btn {
          border: 2px solid $primaryGrey;
        }
      }
    }
  }
}

.radio-list {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  .radio-list-title {
    font-weight: normal;
    font-size: 12px;
    line-height: 96%;
    letter-spacing: 0.01em;
    text-transform: uppercase;
    color: $primaryGrey;
  }

  .radio-list-content {
    padding: 28px 0 0 18px;
  }

  .radio-with-help-text {
    + .radio-with-help-text {
      margin-top: 24px;
    }
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-text-fill-color: var($black);
  -webkit-box-shadow: 0 0 0px 1000px transparent inset;
  transition: background-color 5000s ease-in-out 0s;
  background: -webkit-linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgba(0, 174, 255, 0.04) 50%, rgba(255, 255, 255, 0) 51%, rgba(0, 174, 255, 0.03) 100%);
}

.field {
  .ant-input-number {
    width: 100%;
    padding: 0 16px;
  }

  .ant-input-number-input-wrap,
  .ant-input-number-input {
    height: 100%;
  }

  .ant-input-number-input {
    padding: 0;
  }
}

.field__input {
  min-height: 48px;
}
.ant-select-selector {
  min-height: 48px;
}

.field {
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    min-height: 48px;
    border: 1px solid $black;

    @include mobile() {
      min-height: 40px;
    }
  }

  .ant-select:not(.ant-select-customize-input):hover .ant-select-selector {
    &:hover {
      border: 1px solid $primaryBlue;
    }
  }

  .ant-select.ant-select-open .ant-select-selector {
    border: 1px solid $primaryBlue;
  }
}

.ant-input {
  -webkit-appearance: none !important;
}
