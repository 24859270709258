$mobile-end: 767px;
$tablet-end: 1023px;
$desktop-end: 1279px;

$ranges: (
  mobile: "(max-width: #{$mobile-end})",
  tablet: "(max-width: #{$tablet-end})",
  desktop: "(max-width: #{$desktop-end})"
);

@function get_media($media, $list: $ranges) {
  @return unquote(map_get($list, $media));
}

@mixin media-query($media) {
  @media all and #{get-media($media)} {
    @content;
  }
}

@mixin mobile() {
  @include media-query(mobile) {
    @content;
  }
}

@mixin tablet() {
  @include media-query(tablet) {
    @content;
  }
}

@mixin desktop() {
  @include media-query(desktop) {
    @content;
  }
}