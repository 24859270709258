.tariff-plan {
  position: relative;
  background-color: $white;

  &-header {
    display: flex;
    align-items: center;
    height: 56px;
    margin-bottom: 32px;
    padding: 0 28px;
    background-color: var(--primaryBlueToGreen);

    &__title {
      font-weight: 500;
      font-size: 18px;
      line-height: 96%;
      letter-spacing: -0.03em;
      color: $white;
    }
  }

  &__content {
    padding: 0 16px;
    position: relative;
  }

  &-table {
    position: relative;
    z-index: 1;
    &-header {
      display: flex;

      &__title {
        flex-shrink: 0;
        position: relative;
        width: 100%;
        z-index: 3;
        padding: 18px;
        text-align: center;
        font-weight: 500;
        font-size: 18px;
        line-height: 32px;
        letter-spacing: 0.01em;

        &:first-child {
          max-width: 242px;
        }
        &:not(:first-child) {
          max-width: 290px;
          border-left: 1px solid #F8F9FB;
        }

        img {
          margin-left: 8px;
        }

        .status-label {
          position: absolute;
          bottom: -12px;
          left: 0;
          right: 0;
          margin: 0 auto;
          span {
            font-weight: 400;
            font-size: 12px;
            line-height: 96%;
            letter-spacing: -0.03em;
          }
          &.gray {
            background-color: #EEEFF0;
            span {
              color: rgba(36, 36, 36, 0.71);
            }
          }
        }
      }
    }

    &__inner {
      display: flex;
      position: relative;
      border-top: 1px solid #F8F9FB;
      &:last-child {
        padding-bottom: 32px;
        .tariff-plan-table__col {
          justify-content: space-between;
          .tariff-plan-table__text {
            line-height: 126%;
          }
        }
      }
    }

    &__col {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      flex-shrink: 0;
      width: 100%;
      padding: 18px;
      text-align: center;

      &:first-child {
        max-width: 242px;
        .tariff-plan-table__text {
          width: 100%;
          text-align: left;
          font-size: 14px;
          line-height: 131%;
          letter-spacing: -0.02em;
          color: rgba(36, 36, 36, 0.71);
        }
      }
      &:not(:first-child) {
        border-left: 1px solid #F8F9FB;
        max-width: 290px;
      }

      &.price {
        padding-top: 32px;
        padding-bottom: 0;
      }

      .btn {
        padding: 0 14px;
        max-width: 172px;
        margin: 50px auto 0 auto;
        &.outline-primary {
          border: 1px solid $green;
          color: $green;
        }
        &.primary {
          background-color: $green;
        }
      }
    }

    &__value {
      font-weight: 500;
      font-size: 21px;
      line-height: 26px;
      letter-spacing: -0.02em;
    }

    &__text {
      display: flex;
      font-weight: 400;
      font-size: 16px;
      line-height: 96%;
      letter-spacing: -0.02em;

      &.month {
        margin-top: 6px;
        color: #000000;
      }

      &.year {
        align-items: center;
        margin-top: 18px;
        min-height: 40px;
        color: rgba(36, 36, 36, 0.71);
        line-height: 126%;
        img {
          display: flex;
          align-self: end;
          padding-bottom: 2px;
        }
        &.year-b2b {
          font-weight: 500;
          color: #000000;
        }
      }

      &.price-title {
        padding-top: 16px;
        padding-bottom: 16px;
      }
    }
  }
  &.tariff-b2c {
    .tariff-plan-table-header__title {
      &:first-child {
        max-width: 178px;
      }
      &:not(:first-child) {
        max-width: 232px;
      }
    }

    .tariff-plan-table__col {
      &:first-child {
        max-width: 178px;
      }
      &:not(:first-child) {
        max-width: 232px;
        margin-right: 2px;
      }
    }
    .tariff-plan-table {
      &.active-tariff {
        &:before {
          left: 170px;
          width: 232px;
        }
      }

      &.recommended-tariff {
        &:after {
          content: '';
          position: absolute;
          top: 0;
          height: 95%;
          z-index: 2;
          background: #EDF6F3;;
          left: 411px;
          width: 232px;
        }
      }
    }
  }
}

.tariff-modal {
  .ant-modal-content {
    background: none;
    border-radius: 18px;
  }
  .ant-modal-body {
    padding: 0;
  }

  .ant-modal-close {
    top: 22px;
    right: 34px;
  }

  .ant-modal-close-x {
    width: 16px;
    height: 16px;
    line-height: 16px;
    color: transparentize($white, 0.22);
  }
}
