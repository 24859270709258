$primaryBlue: #00B5E5;
$secondaryBlue: #E1ECF6;
$primaryYellow: #FDD66A;
$opacityYellow: #FEF2CF;
$primaryRed: #E87461;
$secondaryRed: #E88372;
$opacityRed: #f6cdc7;
$black: #000;
$primaryGrey: #979797;
$secondaryGrey: rgba(23, 24, 59, 0.5);
$activeGrey: #8B8B9C;
$green: #229C71;
$white: #fff;
$border: #EEEFF0;
$text: #242424;


:root {
  --primaryBlueToGreen: #3581B8;
  --textBlackToWhite: #ffffff;
}

.energiya-page {
  --primaryBlueToGreen: #367961;
  --textBlackToWhite: #17183B;
}
