.page__content {
  .dashboard {
    .scroll-wrap {
      overflow-x: auto;
      .tab-label-list {

        @include mobile() {
          width: 100%;
          margin-bottom: 20px;
          border-bottom: 0;
        }

        .tab-label {
          width: 50%;

          @include mobile() {
            width: auto;
            height: 30px;
            padding: 0 10px 12px;
            flex-shrink: 0;
          }
        }
      }
    }
  }
}

.dashboard {
  &_gas {
    .ant-table-cell {
      &.number {
        width: 46px;
        max-width: 46px;
        text-align: center !important;
      }

      &.code {
        width: 178px;
      }

      &.address {
        width: 224px;
      }

      &.operator {
        width: 182px;
      }

      &.contracts {
        max-width: 80px;
        width: 80px;
      }

      &.orders {
        max-width: 198px;
        width: 198px;
      }
    }
  }

  &_energiya {
    .dashboard-dots {
      .ant-table-cell {
        padding: 8px 6px !important;

        &.number {
          width: 38px;
          max-width: 38px;
        }

        &.code {
          width: 134px;
        }

        &.address {
          width: 170px;
        }

        &.class,
        &.group {
          width: 52px;
          max-width: 52px;
        }

        &.operator {
          width: 142px;
        }

        &.contracts {
          width: 64px;
        }

        &.month {
          width: 86px;
        }
      }

      th.ant-table-cell {
        &.month {
          height: auto;
          padding: 3px 6px !important;
          font-size: 12px;
          line-height: 12px;
          font-weight: 400;
        }

        &.orders {
          height: auto;
          padding: 6px !important;
          line-height: 16px;
        }
      }

      td.ant-table-cell:not(.summary-title),
      th.ant-table-cell {
        text-align: center !important;
      }

      td.ant-table-cell {
        font-size: 14px;
        font-weight: 400;
        letter-spacing: -0.01em;

        &.code,
        &.address {
          text-align: left !important;
        }
      }
    }
  }

  .custom-ant-table {
    width: 100%;
  }

  &__link-btn {
    padding: 0;
    border: none;
    background-color: transparent;
    font-size: 14px;
    letter-spacing: 0.01em;
    text-decoration: underline;
    color: $primaryGrey;
    cursor: pointer;

    &:hover {
      text-decoration: none;
    }
  }

  &-contract {
    border: 1px solid $secondaryBlue;
    background-color: $white;
    box-shadow: 0 3px 9px rgba(104, 123, 116, 0.08);
    border-top-right-radius: 20px;
    border-bottom-left-radius: 20px;

    + .dashboard-contract {
      margin-top: 20px;
    }

    &-header {
      display: flex;
      justify-content: space-between;
      padding: 38px 34px 28px 34px;

      @include tablet() {
        padding: 30px;
      }

      @include mobile() {
        position: relative;
        flex-wrap: wrap;
        padding: 20px;
      }

      &__right {
        text-align: right;

        @include mobile() {
          margin-top: 20px;
        }
      }

      &__inner {
        display: flex;
        align-items: center;
      }

      &__title {
        display: flex;
        align-items: center;
        font-weight: 500;
        font-size: 21px;
        line-height: 20px;
        letter-spacing: 0.01em;

        @include mobile() {
          font-size: 18px;
        }

        span {
          margin-left: 6px;
          //color: var(--primaryBlueToGreen);
        }
      }

      .status-label {
        margin-left: 18px;

        @include mobile() {
          position: absolute;
          top: 42px;
          right: 20px;
        }

        span {
          @include mobile() {
            font-size: 12px;
          }
        }
      }

      &__date {
        margin-top: 12px;
        font-size: 16px;
        line-height: 24px;
        color: $secondaryGrey;

        @include mobile() {
          margin-top: 5px;
          font-size: 14px;
        }
      }

      &__rate,
      &__price {
        font-size: 16px;
        line-height: 24px;
      }

      &__rate {
        span {
          font-weight: 500;
        }
      }

      &__price {
        margin-left: 8px;
        color: $green;
      }

      &__help {
        display: flex;
        align-items: center;
        margin-left: 8px;
        padding: 0;
        border: none;
        background-color: transparent;
        cursor: pointer;
      }

      .underline-link {
        margin-top: 10px;
      }
    }

    &__btn {
      display: flex;
      align-items: center;
      width: 100%;
      padding: 30px 34px;
      border: none;
      border-top: 1px solid $secondaryBlue;
      background-color: transparent;
      font-size: 18px;
      cursor: pointer;

      @include mobile() {
        padding: 20px;
        font-size: 16px;
      }

      &.active {
        color: var(--primaryBlueToGreen);
        img {
          transform: rotate(90deg);
        }
      }

      img {
        margin-right: 16px;
      }
    }

    &-data {
      padding: 18px 34px 32px 53px;

      @include tablet() {
        padding: 30px;
      }

      @include mobile() {
        padding: 0 20px 20px;
      }

      &.general-info {
        padding: 36px 24px;

        @include mobile() {
          padding: 25px 20px;
        }

        .dashboard-contract-general-header {
          display: flex;
          justify-content: flex-start;
          align-items: center;

          .dashboard-contract-data__title {
            display: flex;
            width: 100%;
            margin-right: 16px;
          }
        }

        .form-selected-filter {
          margin-bottom: 0;
        }

        .dashboard-contract-info-block {
          //min-width: calc(25% - 8px);
          min-width: auto;

          @include tablet() {
            width: 28%;
          }

          @include mobile() {
            margin-left: 0;
          }

          &:first-child {
            max-width: 170px;
            min-width: unset;
            width: 100%;

            @include tablet() {
              max-width: 15%;
            }

            @include mobile() {
              max-width: 31%;
              margin-bottom: 3%;
            }
          }

          &:nth-child(2) {
            @include mobile() {
              width: 66%;
              margin-left: 3%;
              margin-bottom: 3%;
            }
          }

          &:nth-child(3) {
            @include mobile() {
              width: 100%;
              margin-bottom: 3%;
            }
          }

          &:nth-child(4) {
            @include mobile() {
              width: 100%;
            }
          }
        }
      }

      &__title {
        font-weight: 500;
        font-size: 18px;
        line-height: 18px;
        color: $black;
      }

      &__header {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;

        & + .dashboard-history-volume {
          margin-top: 26px;
        }
      }

      .ant-table-bordered * {
        border-color: $secondaryBlue;
      }

      .ant-table-bordered {
        @include mobile() {
          display: flex;
          flex-direction: column;
        }

        table {
          @include mobile() {
            display: flex;
          }
        }
      }

      .ant-table-thead,
      .ant-table-tbody {

        @include mobile() {
          width: 50%;
        }

        tr {
          @include mobile() {
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: stretch;
            justify-content: space-around;
          }
        }

        th {
          @include mobile() {
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            font-size: 12px;
          }
        }

        td {
          @include mobile() {
            height: 100%;
          }
        }
      }

      .dashboard-contract-info-block {
        width: 100%;
        margin: 0 0 3%;
      }
    }

    &-info {
      display: flex;
      margin-top: 26px;

      @include mobile() {
        flex-wrap: wrap;
      }

      &-block {
        min-width: calc(33.3% - 8px);
        padding: 14px 20px;
        border: 1px solid $border;
        border-radius: 0 0 30px 0;

        @include tablet() {
          padding: 14px;
        }

        + .dashboard-contract-info-block {
          margin-left: 8px;
          @include mobile() {
            margin-left: 0;
          }
        }

        &__inner {
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          margin-top: 18px;
          color: $text;

          @include tablet() {
            margin-top: 14px;
          }

          &.red {
            color: $primaryRed;
          }

          &.green {
            color: $green;
          }

          .underline-link {
            margin-left: 20px;

            @include tablet() {
              margin-top: 10px;
              width: 100%;
              margin-left: 0;
              text-align: left;
            }
          }
        }

        &__amount {
          flex-shrink: 0;
          font-size: 30px;
          line-height: 30px;

          @include tablet() {
            font-size: 24px;
          }

          &.black {
            color: $black;
          }
        }

        &__currency {
          margin-left: 6px;
          font-size: 24px;
          line-height: 24px;
          letter-spacing: -0.03em;

          @include tablet() {
            font-size: 22px;
          }

          &.black {
            color: $black;
          }
        }

        &__arrow {
          margin-right: 12px;
        }

        &__error {
          margin-left: 12px;
        }

        &__title {
          @include tablet() {
            font-size: 12px;
          }
        }
      }
    }
  }

  &-dots {
    padding: 28px 34px;
    background-color: $white;
    border: 1px solid $secondaryBlue;
    box-shadow: 0 3px 9px rgba(104, 123, 116, 0.08);
    border-top-left-radius: 20px;
    border-bottom-right-radius: 20px;

    @include mobile() {
      padding: 20px;
    }

    &-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;

      @include mobile() {
        margin-bottom: 15px;
      }

      &__filters {
        display: flex;
        align-items: center;

        @include mobile() {
          width: 100%;
        }

        .field {
          min-width: 196px;

          + .field {
            margin-left: 24px;
          }
        }
      }
    }

    th {
      padding: 8px 14px !important;
      letter-spacing: -0.03em;
    }

    td {
      padding: 5px 12px !important;
      font-size: 16px;
      line-height: 20px;
      letter-spacing: -0.01em;

      &.ant-table-cell.orders {
        text-align: center !important;
      }
    }

    .ant-table-cell {
      text-align: left !important;

      &.summary-title {
        font-weight: 500;
        font-size: 16px;
        letter-spacing: -0.01em;
        color: $black;
      }

      &.summary-value {
        text-align: center !important;
        color: var(--primaryBlueToGreen);
      }
    }

    .ant-table-summary {
      height: 54px;
    }

    .scroll-wrap {
      overflow-x: auto;
      margin-left: -20px;
      margin: 0 -20px;
    }

    .form-selected-filter {
      @include mobile() {
        width: 100%;
        justify-content: space-between;
      }

      .field {
        @include mobile() {
          min-width: 47%;
          width: 47%;
          margin: 0;
        }

        .ant-select-selector {
          @include mobile() {
            min-height: 32px;
          }
        }
      }
    }
  }

  .ant-pagination {
    display: none;
  }

  .dashboard-distributor {
    padding: 30px;

    .custom-ant-table {
      margin-top: 34px;
    }

    .dashboard-distributor__amount {
      font-weight: 400;
      font-size: 30px;
      line-height: 96%;
      letter-spacing: -0.02em;
      color: $black;
    }

    .dashboard-distributor__currency {
      margin-left: 6px;
      font-weight: 400;
      font-size: 24px;
      line-height: 96%;
      letter-spacing: -0.03em;
      color: $black;
    }

    .dashboard-distributor__wrapper {
      display: flex;
    }

    .dashboard-distributor__filters {
      display: flex;
      margin-left: auto;

      .form-selected-filter {
        width: auto;

        .field {
          min-width: 140px;
          margin: 0 0 0 14px
        }
      }
    }

    .ant-table-thead .ant-table-cell {
      height: 38px;
      font-size: 14px;
      font-weight: 400;
    }

    .ant-table-tbody .ant-table-cell {

    }

    .dashboard-distributor__title,
    .dashboard-distributor__text {
      max-width: 460px;
    }

    .dashboard-distributor__title {
      margin-top: 8px;
      font-weight: 500;
      font-size: 21px;
      line-height: 96%;
      letter-spacing: 0.02em;
      color: $black;
    }

    .dashboard-distributor__text {
      margin-top: 12px;
      font-weight: 400;
      font-size: 16px;
      line-height: 144%;
      color: $primaryGrey;
    }
  }

  &__loader {
    min-height: 220px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $white;
    border: 1px solid $secondaryBlue;
    box-shadow: 0 3px 9px rgba(104, 123, 116, 0.08);
    border-top-right-radius: 20px;
    border-bottom-left-radius: 20px;
  }
}

.dashboard-b2c {
  &-item {
    background-color: $white;
    box-shadow: 0 0 20px rgba(57, 59, 61, 0.04);
    border-radius: 0 20px;

    &.new-invoice-item .dashboard-b2c-item__inner {
      padding-bottom: 56px;
    }

    &.loading {
      display: flex;
      justify-content: center;
      align-items: center;
      min-height: 306px;
    }

    + .dashboard-b2c-item {
      margin-top: 20px;
    }

    &__inner {
      padding: 34px;

      @include mobile() {
        padding: 20px;
      }
    }

    &-header {

      @include mobile() {
        border-bottom: 1px solid $secondaryBlue;
      }

      &__inner {
        display: flex;
        align-items: center;
      }

      .status-label {
        margin-left: 20px;
      }

      &__title {
        font-weight: 700;
        font-size: 21px;
        line-height: 20px;
        letter-spacing: 0.01em;

        @include mobile() {
          font-size: 18px;
        }
      }

      &__subtitle {
        margin-left: 7px;
        color: $primaryBlue;

        @include mobile() {
          display: flex;
          width: 100%;
          margin-left: 0;
          margin-top: 10px;
        }
      }

      &__text {
        margin-top: 14px;
        font-size: 16px;
        line-height: 24px;
        opacity: .5;

        @include mobile() {
          margin-top: 10px;
          margin-bottom: 10px;
          font-size: 12px;
        }

        .underline-link {
          margin-left: 15px;

          @include mobile() {
            display: block;
            margin-left: 0;
          }
        }
      }
    }

    &-content {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      margin-top: 50px;

      @include mobile() {
        position: relative;
        padding-top: 110px;
        margin-top: 0;
      }

      &__full {
        width: 85%;
        margin-top: 10px;
      }

      &__left {
        .dashboard-b2c-item-content-data__text {
          margin-left: 10px;
        }

        .tooltip {
          margin: 0 12px 0 8px;
        }

        .status-label {
          margin-left: 14px;
        }
      }

      &__right {
        text-align: right;

        @include mobile() {
          position: absolute;
          top: 12px;
          left: 0;
          width: 100%;
          padding-bottom: 15px;
          border-bottom: 1px solid $secondaryBlue;
          text-align: left;
        }

        .dashboard-b2c-item-content-data__text.bold {
          margin-left: 10px;
        }

        .tooltip {
          margin-left: 8px;
        }

        .underline-link {
          margin-top: 10px;
        }
      }

      &-data {
        padding-left: 16px;
        border-left: 4px solid $primaryBlue;

        &__row {
          display: flex;
          align-content: center;

          + .dashboard-b2c-item-content-data__row {
            margin-top: 10px;
          }

          //&:last-child {
          //  margin-top: 28px;
          //}
        }

        &__text {
          line-height: 24px;
          font-size: 16px;
          opacity: .6;

          &.bold {
            font-weight: 700;
            opacity: 1;
          }

          &.red {
            color: $primaryRed;
          }

          &.green {
            color: $green;
          }
        }
      }
    }

    &__buttons {
      display: flex;
      align-items: center;
      margin-top: 48px;

      .btn {
        min-width: 218px;

        + .btn {
          margin-left: 24px;
        }
      }
    }

    &-table {
      border: 1px solid $border;
      margin-top: 48px;

      &__row {
        display: flex;
        min-height: 58px;

        + .dashboard-b2c-item-table__row {
          border-top: 1px solid $border;
        }
      }

      &__col {
        display: flex;
        align-items: center;
        overflow: hidden;

        + .dashboard-b2c-item-table__col {
          border-left: 1px solid $border;
        }

        &.checkbox-col {
          justify-content: center;
          min-width: 62px;
        }

        &.amount-col {
          max-width: 178px;
          width: 100%;
          padding: 0 18px;
        }

        &.text-col {
          padding: 0 30px;
        }
      }

      &__text {
        width: 100%;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
    }

    &--address {
      .dashboard-b2c-item-content__left,
      .dashboard-b2c-item-content__right {
        width: 50%;

        @include mobile() {
          width: 100%;
        }
      }

      .dashboard-b2c-item-content-data__text {
        margin-left: 0;

        &.bold {
          margin-left: 10px;
        }
      }
    }
  }

  .ant-pagination {
    display: none;
  }

  .dynamic-table {
    text-align: center;

    .ant-table-cell {
      text-align: center;
    }

    th {
      background-color: transparent;
      padding-top: 12px;
      padding-bottom: 12px;
      font-size: 14px;
      line-height: 14px;
      color: $black;
      font-weight: 400;

      @include mobile() {
        font-size: 12px;
      }
    }

    td {
      font-size: 18px;
      line-height: 18px;
      letter-spacing: -0.02em;
      color: $text;
      font-weight: 400;

      @include mobile() {
        font-size: 12px;
      }
    }
  }
}

.amount-to-pay {
  width: 100%;
  display: flex;
  align-items: center;
  margin-top: 56px;
  flex-wrap: wrap;

  .ant-form {
    width: auto;
    flex-direction: row;

    @include mobile() {
      width: 100%;
      flex-direction: column;
      margin-top: 15px;
    }
  }

  .amount-to-pay__input {
    position: relative;
    display: flex;
    align-items: center;
    margin-right: 20px;

    @include mobile() {
      width: 96%;
      margin-bottom: 15px;
    }

    .ant-form-item {
      margin-bottom: 0;

      @include mobile() {
        width: 100%;
      }
    }

    .currency {
      position: absolute;
      right: 10px;
      top: 50%;
      transform: translateY(-50%);
      margin: 0 8px;
      font-size: 16px;
      color: #8b8b9c;
    }
  }

  &__title {
    margin-right: 20px;
    font-weight: 500;
    font-size: 18px;
    white-space: nowrap;
  }

  &__amount {
    margin-left: 84px;
    font-size: 28px;
    letter-spacing: -0.03em;
    color: $text;
  }

  &__currency {
    margin-left: 8px;
    font-size: 16px;
    color: #8b8b9c;
  }

  .btn {
    min-width: 172px;
    height: 40px;

    @include tablet() {
      width: 235px;
    }

    @include mobile() {
      width: 92%;
    }
  }

  .dashboard-download {
    min-width: max-content;
    width: 235px;
    margin-left: auto;
    margin-right: 20px;
    padding: 0 30px;
    background: $secondaryBlue;
    border: none;
    font-weight: 500;

    @include tablet() {
      margin-top: 15px;
    }

    @include mobile() {
      width: 92%;
    }

    .ant-spin,
    .btn__icon-left {
      transform: skew(40deg);
    }

    .ant-spin-dot{
      transform: none;
    }

    span {
      margin-top: 2px;
    }

    &__wrap {
      display: flex;
      margin-left: auto;

      @include tablet() {
        width: 100%;
        margin-top: 20px;
      }

      @include mobile() {
        margin-top: 15px;
      }
    }

    &--tov {
      margin-left: 0;
    }
  }

  .field {
    width: 200px;
    margin-left: 18px;

    &.ant-form-item {
      margin-bottom: 0;
    }

    .field__input {
      width: 100%;
      min-height: 40px;
      height: 40px;
      border-radius: 10px 0;
    }

    .ant-input-number-input-wrap,
    .ant-input-number-input {
      height: 100%;
    }

    .ant-input-prefix {
      order: 2;
      margin-right: 0;
      padding-left: 10px;
    }

    .ant-input-affix-wrapper {
      padding-left: 20px;
      padding-right: 18px;
      border-color: transparent;
      border-right: none;
      cursor: text;
    }

    .ant-input-number-focused,
    .ant-input-number:focus,
    .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover,
    .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):focus,
    .ant-input-affix-wrapper-focused:not(.ant-input-affix-wrapper-disabled),
    .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):focus,
    .field__input:not(.ant-input-affix-wrapper-disabled):hover {
      border-color: $primaryBlue;
      box-shadow: none;
    }

    .ant-input-number-input {
      padding-right: 40px;
      font-size: 28px;
      letter-spacing: -0.03em;
      color: $text;
      text-align: right;
      font-weight: 700;
      -moz-appearance: textfield;
      appearance: textfield;
    }
    .ant-input-number-input::-webkit-inner-spin-button,
    .ant-input-number-input::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }

  .ant-form-item-control-input-content {
    transform: skew(-40deg);

    .ant-input-number-input-wrap {
      transform: skew(40deg);
    }
  }

  &.generate-invoice {
    .amount-to-pay__title {
      width: 100%;
      margin-bottom: 15px;
      @include tablet() {
        margin-bottom: 0;
      }
    }

    .btn {
      margin-left: 0;
    }
  }
}

.dashboard-status-label {
  margin-left: 14px;
  padding: 4px 14px;
  border-radius: 14px;
  font-weight: 500;
  font-size: 12px;
  line-height: 12px;
  letter-spacing: -0.02em;

  &.yellow {
    background-color: #fdd66a;
    color: $black;
  }

  &.blue {
    background-color: $primaryBlue;
    color: $white;
  }
}

.account-management {
  .amount-to-pay {
    margin-top: 32px;
    flex-wrap: wrap;

    .amount-to-pay__title {
      display: flex;
      flex-shrink: 0;
      width: 100%;
      margin-bottom: 22px;
    }
    .field {
      margin-left: 0;
    }
    .field__input {
      border-color: $primaryBlue;
    }
  }
}

.empty-profile {
  margin: 20px 0;
  text-align: center;

  @include tablet() {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  &__top {

    @include mobile() {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    & > img {
      width: 48px;
      margin-bottom: 25px;
    }
  }

  &__title {
    margin-bottom: 15px;
    color: $primaryBlue;
    font-size: 18px;
    font-weight: 500;

    &--energy {
      color: $green;
    }

    @include mobile() {
      width: 160px;
      font-size: 16px;
    }
  }

  &__subtitle {
    max-width: 320px;
    margin: 0 auto 40px;
    font-size: 14px;
    font-weight: 300;

    @include mobile() {
      width: 219px;
    }
  }

  &__links {
    display: flex;
    justify-content: flex-end;
    margin-top: 15px;

    @include tablet() {
      align-self: flex-end;
    }

    @include mobile() {
      flex-direction: column;
      align-self: center;
    }

    .underline-link {
      margin-left: 20px;
      color: $primaryBlue;

      @include mobile() {
        margin-top: 5px;
        margin-left: 0;
      }
    }
  }

  &__tooltip {
    color: $primaryGrey;
    font-size: 14px;

    img {
      margin-left: 5px;
    }
  }

  &__btn {
    .btn {
      width: 230px;
    }
  }
}

.empty-profile-widget {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 30px;
  border-radius: 30px;
  background-color: $white;

  @include mobile() {
    flex-direction: column;
    padding: 30px 20px;
  }

  &__wrap {
    text-align: left;
  }

  &__header {
    margin-bottom: 10px;
    font-size: 24px;
    font-weight: 500;

    @include mobile() {
      font-size: 20px;
    }
  }

  &__subheader {
    max-width: 90%;
    font-size: 14px;
    font-weight: 300;
  }

  &__btn {
    flex-shrink: 0;
    margin-right: 15px;

    @include mobile() {
      width: 100%;
      margin: 15px 20px 0;
      text-align: center;
    }

    .btn {
      height: 40px;
      padding: 5px 30px;
      border-radius: 10px 0;
      border: 1px solid $activeGrey;
      background-color: $white;
      font-weight: 500;
      transition: border-color 250ms ease-in-out;

      @include mobile() {
        width: 90%;
      }

      &:hover,
      &:focus {
        border-color: $primaryBlue;
        span {
          color: $black;
        }
      }

      img {
        transform: skewX(40deg);
      }

      span {
        margin-top: 2px;
        margin-left: 5px;
      }
    }
  }
}

.add-profile-form {
  padding: 20px 0 40px;

  &__title {
    width: 80%;
    margin-bottom: 50px;
    font-size: 40px;
    font-weight: 700;
    line-height: 45px;

    @include mobile() {
      width: 100%;
    }
  }

  &__subtitle {
    margin-bottom: 25px;
    font-size: 18px;
    font-weight: 500;
    text-align: center;
  }

  .field {
    max-width: 350px;
    width: 100%;
  }

  .btn {
    width: 65%;
    margin-top: 10px;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type=number] {
    -moz-appearance: textfield;
  }
}

.request-b2c {
  padding-bottom: 30px;
  background-color: $white;
  box-shadow: 0 0 20px rgba(57, 59, 61, 0.04);
  border-radius: 0 20px;

  &__table {
    padding-bottom: 15px;
    overflow-x: auto;
    overflow-y: hidden;
  }

  &__filters {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 34px;

    @include mobile() {
      flex-direction: column;
      align-items: flex-start;
      padding: 20px;
    }
  }

  &__filters-wrap {

    .filters-form {
      display: flex;
      align-items: center;
      flex-direction: row;

      @include tablet() {
        flex-direction: column;
        align-items: flex-start;
      }

      .field {
        min-width: 196px;
        margin-right: 24px;

        @include tablet() {
          margin-bottom: 15px;
        }

        &:last-child {
          margin-right: 0;
        }

        .ant-form-item {
          margin-bottom: 0;

          .ant-select-selector {
            border: 1px solid $secondaryBlue;
          }
        }

      }

      .ant-picker-range {
        height: 48px;
        border: 1px solid $secondaryBlue;
        border-radius: 10px;

        &:hover {
          border: 1px solid $primaryBlue;
        }

        &.ant-picker-focused {
          box-shadow: none;
          border: 1px solid $primaryBlue;
        }
      }
    }
  }

  .custom-ant-table {
    width: 100%;

    .request-cell {
      display: flex;
      flex-direction: column;

      &__bold {
        font-weight: 600;
      }
    }

    .request_status {
      padding: 8px 25px;
    }

    .request-cell-status {
      display: flex;
      align-items: center;
      width: 130px;
      margin: 0 auto;
      padding: 6px 16px;
      border-radius: 30px;
      color: $white;
      font-size: 12px;
      line-height: 12px;

      &__text {
        margin-left: 10px;
      }

      &__rejected {
        background-color: $primaryRed;
      }

      &__processing,
      &__wait_pay {
        background-color: $primaryYellow;
        opacity: .8;
        color: $black;
      }

      &__wait_pay {
        width: 165px;
      }

      &__executed {
        background-color: $green;
      }
    }
  }
}

.dashboard-add-account {
  margin-top: 30px;
}

.add-iit-form {
  width: 1200px !important;

  .ant-modal-body {
    padding: 30px;
  }
}

.iit-widget {
  height: 550px;
}

#textAreaData {
  display: none;
}

.sign-modal-error {
  color: $primaryRed;
}

.invoice-pay-modal {
  .amount-to-pay-form {
    display: flex;
    flex-direction: column;
  }

  .amount-to-pay-position {
    display: flex;
    position: relative;

    .amount-to-pay-position__label {
      position: absolute;
      top: 40px;
      left: 0;
      right: 0;
      text-align: center;
    }
  }

  /*.ant-form {
    width: auto;
    flex-direction: row;

    @include mobile() {
      width: 100%;
      flex-direction: column;
      margin-top: 15px;
    }
  }*/
  .ant-input-number-input {
    padding-right: 40px;
    font-size: 28px;
    letter-spacing: -0.03em;
    color: $text;
    text-align: right;
    font-weight: 700;
    -moz-appearance: textfield;
    appearance: textfield;
  }

  .ant-input-number-input::-webkit-inner-spin-button,
  .ant-input-number-input::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  .amount-to-pay__input {
    position: relative;
    display: flex;
    align-items: center;
    margin-right: 20px;

    @include mobile() {
      width: 96%;
      margin-bottom: 15px;
    }

    label {
      font-size: 12px;
      color: $primaryGrey;
    }

    .ant-form-item {
      margin-bottom: 0;

      @include mobile() {
        width: 100%;
      }

      .ant-select-selector {
        border-radius: 10px;
        border: 1px solid $primaryGrey;
      }
    }

    .currency {
      position: absolute;
      right: 10px;
      top: 50%;
      transform: translateY(-50%);
      margin: 0 8px;
      font-size: 16px;
      color: #8b8b9c;
    }
  }

  .btn {
    padding: 0 45px;
  }

  .ant-input-number {
    border-radius: 10px;
  }

  .field__input-wrap {
    position: relative;
  }
}
