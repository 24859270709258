.progressbar {
  display: flex;
  flex-direction: column;
  width: 100%;

  &__text {
    margin-bottom: 5px;
    font-weight: 500;
    font-size: 14px;
    line-height: 150.5%;
    letter-spacing: -0.02em;
  }

  &__line-bg {
    width: 100%;
    height: 16px;
    border-radius: 15px;
    background: $secondaryBlue;
    box-shadow: inset 0 0 4px rgba(46, 88, 153, 0.2);
  }

  &__line {
    height: 16px;
    background: $primaryBlue;
    border-radius: 15px;
    background:
            linear-gradient(270deg, #1483C8 0%, #5EB7E3 68.02%, #5EB7E3 100%),
            linear-gradient(270deg, #229C71 0%, rgba(45, 202, 174, 0.757333) 68.02%, rgba(42, 182, 132, 0.48) 100%);
  }
}
