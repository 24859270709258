.energiya-page {
  .tab-label {
    &.secondary {
      &.active {
        background-color: $primaryYellow;
      }
    }
    &.primary {
      &.active {
        color: $black;
        &::after {
          background-color: $green;
        }
      }
    }
  }
}
.tab-label {
  &.primary {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    height: 56px;
    width: 50%;
    font-weight: 300;
    font-size: 16px;
    line-height: 96%;
    letter-spacing: 0.01em;
    color: $black;
    cursor: pointer;
    z-index: 2;

    &.active {
      color: $primaryBlue;
      font-weight: 500;
      border: none;

      &:after {
        content: '';
        width: 100%;
        height: 4px;
        position: absolute;
        bottom: 0;
        left: 0;
        background: $primaryBlue;
      }
    }
  }

  &.secondary {
    position: relative;
    min-width: 180px;
    height: 38px;
    color: transparent;
    border-radius: 10px 0 10px 0;
    cursor: pointer;

    @include tablet() {
      min-width: 145px;
    }

    .tab-label__text {
      position: absolute;
      top: 0;
      left: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
      transform: skew(40deg);
      font-weight: 300;
      font-size: 14px;
      color: $black;

      @include tablet() {
        font-size: 12px;
      }
    }

    &.active {
      background-color: $primaryBlue;

      .tab-label__text {
        font-weight: 500;
        color: var(--textBlackToWhite);
      }
    }
  }

  &.disabled {
    opacity: 0.3;
    cursor: not-allowed;
  }
}

.tab-label-list:not(.skew) {
  display: flex;
  margin-bottom: 30px;
  border-bottom: 1px solid #D8DEDC;

  @include mobile() {
    margin-bottom: 20px;
  }
}

.tab-label-list.skew {
  display: flex;
  align-items: center;
  transform: skew(-40deg);
  background-color: #F7FBFF;
  border-radius: 10px 0 10px 0;

  @include mobile() {
    margin-top: 20px;
    width: 100%;
  }

  .tab-label {
    &__text {
      @include mobile() {
        font-size: 10px;
      }
    }
    &.secondary {
      @include mobile() {
        min-width: 104px;
        width: 33%;
        height: 26px;
      }
    }
  }
}
