.my-team-item {
  padding: 34px;

  .header-item-wrap {
    display: flex;
    align-items: flex-start;
    padding-bottom: 30px;

    @include tablet() {
      flex-direction: column;
    }

    @include mobile() {
      position: relative;
    }
  }

  .my-team-item-header {
    display: flex;
    align-items: center;

    @include mobile() {
      flex-direction: column;
    }

    .box-row-text {
      max-width: 47%;
    }

    .box-shadow-title {
      margin-bottom: 0;
      margin-right: 14px;
    }
  }

  .subheader-wrap {
    display: flex;
    align-items: center;

    @include mobile() {
      margin: 20px 0 35px;
    }
  }

  .headers-wrap {
    flex-shrink: 0;

    @include tablet() {
      flex-shrink: 1;
    }

    .box-shadow-sub-title {
      @include tablet() {
        margin-bottom: 0;
      }
    }

    .link--red {
      @include mobile() {
        position: absolute;
        bottom: 20px;
        left: 0;
        right: 0;
        margin: 0 auto;
      }
    }
  }

  .button-wrap {
    width: 100%;
    display: flex;
    align-items: flex-start;

    @include tablet() {
      align-items: center;
    }

    @include mobile() {
      padding-bottom: 50px;
    }

    .status-label {
      @include mobile() {
        position: absolute;
        bottom: calc(50% + 35px);
        right: 0;
      }
    }
  }

  .my-team-item-sub-header {
    margin-bottom: 0;
    margin-right: 30px;
    font-weight: 300;
  }

  .info-box-grid {
    margin: 0 -34px;
    padding: 30px 34px 0;
    height: max-content;
    border-top: 1px solid $secondaryBlue;

    @include mobile() {
      margin: 0 -20px;
      padding: 20px 25px 0;
    }

    .info-box-grid-title + .info-box-grid-title {
      margin-top: 20px;
    }
  }

  .outline-primary {
    display: flex;
    margin-left: auto;
    color: $primaryRed;
    border-color: $primaryRed;
  }

  .btn {
    margin-right: 22px;
  }

  .edit-btn {
    margin-left: auto;
    padding: 10px 40px;
  }

  .info-box-grid-title {
    @include mobile() {
      display: flex;
      flex-direction: column;
      font-size: 14px;
    }

    span {
      @include mobile() {
        margin-top: 7px;
      }
    }
  }
}

.item-rounded {
  border-top-right-radius: 20px;
  border-bottom-left-radius: 20px;

  .btn {
    height: 40px;
    margin-right: 22px;

    @include mobile() {
      margin: 0 auto;
    }
  }
}

.my-team {
  .box-shadow {
    @include mobile() {
      padding: 20px;
    }
  }

  .box-shadow-sub-title {
    @include mobile() {
      font-size: 14px;
      margin-bottom: 20px;
    }
  }

  .box-row {
    @include mobile() {
      flex-direction: column;
    }

    .btn {
      @include mobile() {
        width: calc(100% - 45px);
        font-size: 14px;
      }
    }
  }

  .box-row-text {
    @include mobile() {
      max-width: 100%;
    }
  }
}

