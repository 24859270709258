.registration {
  display: flex;
  justify-content: center;
  min-height: 630px;
  width: 100%;
  margin: 0 auto;

  @include desktop() {
    padding-top: 50px;
  }

  @include mobile() {
    flex-direction: column;
  }

  &__main {
    @include desktop() {
      flex-direction: column;
      padding-top: 0;
    }
  }

  .registration-sidebar {
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    background-color: $white;
    color: $black;
    padding: 50px;
    margin-right: 40px;
    max-width: 427px;
    box-shadow: 0 0 20px rgba(57, 59, 61, 0.04);
    border-radius: 0 40px;

    @include desktop() {
      max-width: 230px;
      height: 100%;
      padding: 30px 20px;
      margin-right: 20px;
      margin-bottom: 30px;
    }

    @include mobile() {
      max-width: initial;
      width: 100%;
      //margin: 0;
      padding: 40px 20px;
    }

    &__main {
      @include desktop() {
        order: 2;
        max-width: initial;
        width: 100%;
        //margin: 0;
      }
    }

    .registration-sidebar-h2 {
      font-size: 36px;
      line-height: 36px;
      letter-spacing: 0.015em;
      font-weight: 700;
      margin-bottom: 80px;

      @include mobile() {
        margin-bottom: 20px;
        font-size: 26px;
        line-height: 26px;
      }
    }

    .sidebar-list-image-container {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-shrink: 0;
      border-radius: 12px 0 12px 0;
      width: 48px;
      height: 48px;
      background-color: $primaryBlue;
      margin-top: 4px;
    }

    .registration-sidebar-list {
      max-width: 250px;
      display: flex;
      flex-direction: column;

      @include desktop() {
        display: block;
        column-count: 2;
        column-gap: 50px;
        max-width: initial;
      }

      @include mobile() {
        column-count: 1;
      }
    }

    .registration-sidebar-item {
      display: flex;
      align-items: flex-start;
      margin-bottom: 30px;

      span {
        margin-left: 18px;
        font-weight: 300;
        font-size: 14px;
        line-height: 130.5%;
      }
    }
  }

  .reg-sidebar-title {
    margin-bottom: 34px;
    font-weight: 500;
    font-size: 16px;
    line-height: 96%;
    letter-spacing: 0.015em;
  }

  .reg-sidebar-sub-title {
    margin-bottom: 30px;
    font-weight: 500;
    font-size: 16px;
    line-height: 130.5%;

    @include mobile() {
      margin-bottom: 15px;
      font-size: 14px;
      line-height: 17px;
    }
  }

  .reg-sidebar-text {
    margin-bottom: 25px;
  }

  .registration-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    background-color: $white;
    box-shadow: 0 0 20px rgba(57, 59, 61, 0.04);
    border-radius: 0 40px;

    @include desktop() {
      padding: 100px 0;
      margin-bottom: 30px;
    }

    @include mobile() {
      padding: 40px 10px;
    }

    &__title {
      text-align: center;
      margin-bottom: 40px;
      font-weight: 700;
      font-size: 40px;
      line-height: 96%;

      @include mobile() {
        margin-bottom: 24px;
        font-size: 30px;
        line-height: 30px;
      }
    }

    &__sub-title {
      max-width: 400px;
      margin-bottom: 45px;
      font-weight: 500;
      font-size: 18px;
      line-height: 120%;
      text-align: center;

      @include mobile() {
        width: 90%;
        margin-bottom: 30px;
        font-size: 14px;
        line-height: 17px;
      }
    }

    &__text {
      font-size: 14px;
      line-height: 17px;
      font-weight: 300;
    }

    &.step3-contract {
      padding: 50px 16px;

      .checkbox {
        margin-top: 28px;
      }
    }

    &.step3-without-contract {
      padding: 50px 16px;

      .checkbox {
        margin-top: 0;
      }
    }

    form {
      .btn {
        width: 310px;
        max-width: 350px;
        margin-top: 28px;
      }
    }

    > img {
      width: 44px;
      height: 44px;
      margin-bottom: 20px;
    }

    .underline-link {
      color: $primaryBlue;
      text-align: right;
      width: 350px;
    }

    label {
      margin-bottom: 7px;
      text-transform: none;
    }
  }

  .reg-content-buttons {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50%;

    @include desktop() {
      width: 60%;
    }

    @include mobile() {
      width: 80%;
      flex-direction: column;
    }

    .btn {
      border-radius: 10px 0;
      transform: skew(-40deg);

      &__text {
        transform: skew(40deg);
      }
    }

    a:first-child {
      margin-right: 30px;

      @include mobile() {
        margin-right: 0;
        margin-bottom: 10px;
      }
    }
  }

  .reg-content-footer {
    display: flex;
    flex-direction: column;
    margin-top: 68px;
    font-weight: normal;
    font-size: 16px;
    line-height: 135.5%;
    text-align: center;
    letter-spacing: 0.015em;
    color: $black;

    &.block {
      margin-top: 176px;
      a {
        display: flex;
      }
    }

    a {
      color: $primaryBlue;
      text-decoration: underline;
      &.link-button {
        text-decoration: none;
      }
    }
    span + span {
      margin-top: 14px;
    }
  }

  .reg-content-title-black {
    max-width: 430px;
    text-align: center;
    color: $black;
    font-weight: 500;
    font-size: 18px;
    line-height: 135.5%;
    letter-spacing: 0.015em;
    margin-bottom: 52px;

    &.block {
      display: block;
    }

    .email {
      color: $primaryBlue;
    }

    .text {
      font-weight: 400;
      font-size: 16px;
    }
  }

  .reg-content-title-blue {
    margin-bottom: 40px;
    color: $primaryBlue;
    font-size: 18px;
    line-height: 1;
  }

  .reg-sidebar-back-to {
    display: flex;
    align-items: center;
    width: max-content;
    margin-bottom: 68px;
    cursor: pointer;

    @include desktop() {
      position: absolute;
      top: 125px;
      left: 20px;
    }

    span {
      color: rgba(0, 0, 0, .5);
      margin-left: 12px;
      font-weight: normal;
      font-size: 16px;
      line-height: 1;
    }
  }

  .checkbox-field {
    width: auto;
    margin-top: 50px;
  }
}

.react-tel-input .special-label {
  display: none;
}

.reg-links {
  margin-top: 50px;
  opacity: .7;

  .reg-link {
    span {
      color: $primaryBlue;
      text-decoration: underline;
      cursor: pointer;

      &:hover {
        text-decoration: none;
      }
    }
  }
}
