.checkbox {
  display: flex;
  align-items: center;
  cursor: pointer;

  &.disabled {
    pointer-events: none;

    .checkbox__btn {
      border-color: gray;
    }
  }


  &.checked {
    .checkbox__btn {
      border-color: $primaryBlue;
      background-color: $primaryBlue;
    }
  }

  &__input {
    display: none;
  }

  &__btn {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    width: 24px;
    height: 24px;
    padding: 0;
    border: 2px solid $primaryBlue;
    background-color: transparent;
    cursor: inherit;
    transition: border-color 0.3s, background-color 0.3s;

    .ant-form-item-has-error & {
      border-color: $primaryRed;
    }

    .icon {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  &__label {
    margin-left: 12px;
    font-weight: normal;
    font-size: 16px;
    line-height: 135.5%;
    text-align: center;
    letter-spacing: 0.015em;
    color: $black;
  }
}
