.btn {
  position: relative;
  display: inline-flex;
  height: 48px;
  justify-content: center;
  align-items: center;
  text-align: center;
  border: 0;
  cursor: pointer;
  outline: 0;
  text-decoration: none;
  line-height: 20px;
  transition: background 0.3s, color 0.3s, border-color 0.3s, box-shadow 0.3s;
  transform: skew(-40deg);
  border-radius: 10px 0;

  &.primary {
    background-color: $primaryBlue;
    color: $white;
    font-weight: 500;
    font-size: 16px;
    line-height: 96%;
    letter-spacing: 0.01em;

    &.disabled {
      background-color: rgba(53, 129, 184, 0.24);
      cursor: not-allowed;
    }

    &.primary-green {
      background-color: $green;
      &.disabled {
        background: rgba(54, 121, 97, 0.24);
      }
    }
  }

  & > span {
    transform: skew(40deg);
    line-height: 1;
  }


  &.outline-primary {
    padding: 16px 34px;
    border: 1px solid $primaryBlue;
    background-color: $white;
    font-weight: 500;
    font-size: 16px;
    color: $primaryBlue;
    line-height: 15px;
    letter-spacing: 0.01em;

    &.outline-primary-green {
      border-color: $green;
      color: $green
    }
    &.outline-primary-red {
      border-color: $primaryRed;
      color: $primaryRed
    }

    img {
      margin-bottom: 2px;
      margin-right: 5px;
    }
  }

  &.button-sign {
    &--disabled {
      filter: grayscale(1);
      pointer-events: none;

      .btn__text {
        color: $secondaryGrey;
      }
    }
  }

  &.outline-secondary {
    padding: 14px 20px;
    border: 1px solid $black;
    background-color: $white;
    font-size: 16px;
    color: $black;
    line-height: 15px;
    letter-spacing: 0.02em;

    &.disabled {
      opacity: 0.3;
      cursor: not-allowed;
    }

    img {
      margin-bottom: 2px;
      margin-right: 10px;
    }
  }

  &__icon-left {
    margin-right: 10px;
  }

  .btn__loading {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    transform: skew(40deg);

    &:after {
      position: absolute;
      content: '';
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: $white;
      opacity: 0.6;
      transform: skew(-40deg);
    }

    .ant-spin-dot {
      z-index: 2;
    }
  }

  &.dashboard-download {
    .btn__loading {
      transform: skew(0deg);
      &:after {
        transform: skew(0deg);
      }
    }
  }

  &--sign {
    padding: 12px 55px;
  }

  &--right {
    margin-left: auto;
    margin-right: 30px;
  }

  &.link {
    height: 16px;
    line-height: 16px;
    background-color: transparent;
    text-decoration: underline;
    font-weight: 300;

    &:hover {
      text-decoration: none;
    }

    &--red {
      color: $primaryRed;
    }
  }
}

.button-label-complete {
  display: flex;
  align-items: center;
  flex-shrink: 0;
  margin-left: 20px;
  padding: 7px 25px 5px 15px;
  border-radius: 20px;
  background-color: $secondaryBlue;
  font-size: 12px;
  line-height: 1;

  img {
    margin-bottom: 1px;
  }

  span {
    margin-left: 12px;
  }

  &--first {
    margin-right: 20px;
  }

  &--sign {
    border: 1px solid $primaryBlue;
    cursor: pointer;
  }

  &--right {
    margin-left: auto;
    margin-right: 30px;
  }

  &--canceled {
    border: 1px solid $secondaryRed;
    background-color: $opacityRed;
  }

  &--rejected {
    border: 1px solid $primaryYellow;
    background-color: $opacityYellow;
  }
}

.ant-table-cell {
  &.documents {
    .button-label-complete {
      margin: 0 auto;
    }
  }
}

.button {
  &--download {
    color: $black;

    img {
      margin-right: 10px;
    }
  }
}